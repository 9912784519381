import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const Sitemap = () => {
  const { site, allSitePage } = useStaticQuery(graphql`
    query SitePages {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);

  const pages = allSitePage.nodes;
  const siteUrl = site.siteMetadata.siteUrl;

  const xml = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${pages
        .map((page) => {
          return `
            <url>
              <loc>${siteUrl}${page.path}</loc>
            </url>
          `;
        })
        .join("")}
    </urlset>`;

  return (
    <div className="content-block" style={{ marginTop: -60 + "px" }}>
      <div className="container">
        <h1 className="default-title">Sitemap</h1>
        <>{xml}</>
      </div>
    </div>
  );
};

export default Sitemap;
